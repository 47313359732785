export async function getUserInfo() {
    let res = await fetch('/api/user-info');
    if (res.ok) {
        return await res.json();
    } else {
        throw new Error(`${res.status}`);
    }
}

export async function ensureLoginOrRedirect() {
    try {
        return await getUserInfo();
    } catch (e) {
        console.error(e);
        if (e.message === '401') {
            window.location.href = `/oauth2/aliyun/auth?redirect_uri=${encodeURIComponent(location.pathname + location.search)}`;
        }
    }
}