import 'normalize.css';
import './style.css';
import { Router } from 'preact-router';
import Home from './routes/home';
import NewRun from './routes/new-run';
import RunDetail from "./routes/run-detail";
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import {useEffect, useState} from "preact/hooks";
import {ensureLoginOrRedirect} from "./api";

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

const App = () => {
	const [userInfo, setUserInfo] = useState(null);
	useEffect(() => {
		ensureLoginOrRedirect().then(setUserInfo);
	}, []);
	return (
		<>
			<header>
				<div className="logo"><img src="/assets/logo.png" alt=""/></div>
				<h1>储能规划工具</h1>
				{userInfo && <h2>
					登录用户：{userInfo.name} {userInfo.username}
				</h2>}
			</header>
			<main>
				<Router>
					<Home path="/" />
					<NewRun path="/new-run" />
					<RunDetail path="/run-detail" />
				</Router>
			</main>
		</>
	);
};

export default App;